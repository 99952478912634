import React, { useState } from "react";
import { Button, Form, Input, Select, Upload, Modal, DatePicker, Checkbox, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 16,
  },
};

const App = () => {

  const [department, setDepartment] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const handleCancel = () => {
    setIsInfoModalVisible(false);
    setIsErrorModalVisible(false);  
    setIsSuccessModalVisible(false);  
  };

  const fileProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList)
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onFinish = values => {

    setIsInfoModalVisible(true);
    setIsErrorModalVisible(false);  
    setIsSuccessModalVisible(false);  

    const formData  = new FormData();
    
    // fileList.forEach(file => {
    //   formData.append('files[]', file);
    // });

    for(const name in values) {
      if(name !== "attachments"){
        formData.append(name, values[name]);
      }
    }

    const requestOptions = {
      method: 'POST',
//      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData
    };
    
    fetch('https://kvjazfts0j.execute-api.ap-southeast-2.amazonaws.com/default/engage-era', requestOptions)
      .then(function(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }).then(function(response) {
        console.log("ok");
        setIsInfoModalVisible(false);
        setIsErrorModalVisible(false);  
        setIsSuccessModalVisible(true);  
    }).catch(function(error) {
        console.log(error);
        setIsInfoModalVisible(false);
        setIsErrorModalVisible(true);  
        setIsSuccessModalVisible(false);  
    });

  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const updateDepartment = value => {
    setDepartment(value)
  }

  const renderCommon = () => {
    return (
      <>
        <h2>Your Details</h2>
        <Form.Item
          label="Name"
          name="requestorName"
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="requestorEmail"
          type="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email address',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mobile"
          name="requestorMobile"
          type="tel"
          rules={[
            {
              required: true,
              message: 'Please enter your mobile number',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <h2>Project Details</h2>
        <Form.Item
          label="Project name"
          name="projectName"
          type="text"
          rules={[
            {
              required: true,
              message: 'Please enter a project name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Project number"
          name="projectNumber"
          type="text"
          rules={[
            {
              required: false,
            },
          ]}
        >
        <Input />
        </Form.Item>
        <Form.Item
          label="Studio"
          name="studio"
          type="text"
          rules={[
            {
              required: true,
              message: 'Please enter a studio',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project type"
          name="projectType"
          rules={[
            {
              required: true,
              message: 'This field is required.',
            },
          ]}
        >
          <Select
            options={[
              { value: "Project (already won)", label: "Project (already won)" },
              { value: "Bid" , label: "Bid" },
              { value: "Competition", label: "Competition" },
              { value: "Other", label: "Other" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Client"
          name="client"
          type="text"
          rules={[
            {
              required: true,
              message: 'Please enter a client',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Please describe the project"
          name="description"
          rules={[
            {
              required: true,
              message: 'This field is required.',
            },
          ]}
        >
          <Input.TextArea/>
        </Form.Item>

        { department === "Productions" &&
          <>
            <hr/>
            <h3>Productions Engagement type</h3>
            <h4>CGI</h4>
            <Form.Item
              label="Please select"
              name="engagementCgi"
              rules={[
                {
                  required: false,
                  message: 'This field is required.',
                },
              ]}
            >
              <Checkbox.Group options={['Exterior', 'Interior', 'Film', 'Interactive', 'Render']} />
            </Form.Item>
            <h4>Brand Narrative</h4>
            <Form.Item
              label="Submission type"
              name="engagementBrandNarrative"
              type="text"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
            options={[
              { value: "Document" , label: "Document" },
              { value: "Storyboard", label: "Storyboard" },
              { value: "Print", label: "Print" },
              { value: "Digital", label: "Digital" },
            ]}
          />
            </Form.Item>
            <h4>Wayfinding &amp; Signage</h4>
            <Form.Item
              label="Scale of project"
              name="engagementWayfinding"
              type="text"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <hr/>
          </>
        }

        {/* <Form.Item
          label="Attachments (Brief or other information)"
          name="attachments"
          rules={[
            {
              required: false,
            },
          ]}
        >
        <Upload {...fileProps}>
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
        </Form.Item> */}

        <Form.Item
          label="Budget (for ERA)"
          name="budget"
          type="text"
          rules={[
            {
              required: true,
              message: 'Please enter a budget',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Will this project require an MS91?"
          name="ms91"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            options={[
              { value: "MS91 Required - ERA-co fee additional to W-B project fee", label: "MS91 Required - ERA-co fee additional to W-B project fee" },
              { value: "MS91 Required – ERA-co fee paid from W-B project fee", label: "MS91 Required – ERA-co fee paid from W-B project fee" },
              { value: "Direct Engagement Opportunity", label: "Direct Engagement Opportunity" },
            ]}
            onChange={updateDepartment}
          />
        </Form.Item>

        <Form.Item
          label="Is this supported by the CIF?"
          name="cif"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Radio.Group>
            <Radio value={'yes'}>Yes</Radio>
            <Radio value={'no'}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Timing"
          name="timing"
          rules={[
            {
              required: true,
              message: 'This field is required.',
            },
          ]}
        >
        <DatePicker.RangePicker format='YYYY/MM/DD' />
        </Form.Item>
        <Form.Item
          label="Is your enquiry urgent? "
          name="urgent"
          rules={[
            {
              required: false
            },
          ]}
        >
          <Radio.Group>
            <Radio value={'yes'}>Yes</Radio>
            <Radio value={'no'}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <p>If so an ERA-co team member will contact you within three hours.  If not urgent, ERA-co will contact you within 24 hours.</p>
      </>
    )
  }

    return (
    <div className="form">
    <h1>ERA-co Engagement Request</h1>
    <p>Please provide details of your project engagement request below and a member of the ERA team will be in touch.</p>
    <div className="formFields">
       
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="ERA Department"
          name="eraDepartment"
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
        <Select
            options={[
              { value: "Brand Experience", label: "Brand Experience" },
              { value: "Productions", label: "Productions" },
              { value: "R&D", label: "R&D" },
              { value: "Urban Systems", label: "Urban Systems" },
              { value: "User Strategy", label: "User Strategy" },
              { value: "All Departments", label: "All Departments" },
              { value: "Not sure", label: "I'm not sure ..." },
            ]}
            onChange={updateDepartment}
          />
        </Form.Item>
        { renderCommon() }
        {/* <Form.Item
          label="Engagement Type"
          name="engagementType"
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            options={[
              { value: 1, label: "Client project / bid" },
              { value: 2, label: "Existing tool enhancement" },
              { value: 3, label: "New tool proposal" },
              { value: 4, label: "Other Marketing / New business / Industry engagement" },
              { value: 5, label: "Other" },
            ]}
            onChange={updateEngagementType}
          />
        </Form.Item>
     {renderEngagementType(engagementType)} */}

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    <Modal title="Sending" visible={isInfoModalVisible} footer={null}>
        <p>Please wait ...</p>
    </Modal> 
    <Modal title="Success" visible={isSuccessModalVisible} footer ={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>]}>       
            <p>Your engagement enquiry has been sent. A member of ERA will be in touch</p>
    </Modal>
    <Modal title="Error" visible={isErrorModalVisible} footer ={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>]}>    
        <p>Uh oh! - something went wrong ... Please email <a href="mailto:stefanie.rankin@era-co.com">stefanie.rankin@era-co.com</a></p>
    </Modal>
    <br/><br/><br/>
    </div>
    </div>
  );
};

export default App;